
/* #########################################################################
* # main
* ######################################################################### */

@charset 'UTF-8';

/* custom stylsheets */

// @import 'tools/typo-font-face'; // 04mrch19
// @import './bootstrap/scss/bootstrap';
@import './mdbootstrap-pro/css/bootstrap';
// @import './bootstrap/scss/reboot';
@import './mdbootstrap-pro/scss/mdb';

// To convert CSS into SCSS http://css2sass.herokuapp.com/
:root {

  /* ----------- define color variables -----------*/
  $facebook-color: #3b5998;
  $twitter-color: #4da7de;
  $pinterest-color: #b51205;
  $linkedin-color: #3371b7;
  $googleplus-color: #c93725;
  $rss-color: #f26109;
  $boat-color: #1a1a3f;
  $boat-light-color: #5f8bb2;
  $bghighlight-color: #5f8bb2;
  $coldefault-color: #ecf0f1;
  $colhighlight-color: #ffbbbc;
  $white: #fff;

  --fill-color-facebook: $facebook-color;
  --fill-color-twitter: $twitter-color;
  --fill-color-pinterest: $pinterest-color;
  --fill-color-linkedin: $linkedin-color;
  --fill-color-google-plus: $googleplus-color;
  --fill-color-rss: $rss-color;
  --fill-color-boat: $boat-color;
  --fill-color-boat-light: $boat-light-color;
  --bghighlight: $bghighlight-color;
  --coldefault: $coldefault-color;
  --colhighlight: $colhighlight-color;
  --fill-color-white: $white;
  $dropdown: false;
  --Font-Size: 20px;
}

$colorfloralwhite: #fffaf0;
$boat-color: #1a1a3f;
$colorborderlistgroup: #808080;
$color-google-menu: #ffebcd;
$colorgrijs: #bbc2c2;
$bgdefault: #1a1a3f;

@mixin bg-gradient { // ()
  transition: .5s; // all
  // background: rgb(187, 194, 194);
  background: linear-gradient(90deg, rgba(187, 194, 194, 1) 0%, rgba(1, 72, 136, 1) 35%, rgba(26, 26, 63, 1) 100%);
}

/*! purgecss start ignore */

.bg-gradient {
  @include bg-gradient;
}

element {
  position: relative;
  min-height: 100%;
  top: 0;
}

.navbar a:not(.btn):not(.dropdown-item) {
  color: $colorfloralwhite; // floralwhite --> make text a bit brighter when on the transparent navbar
  font-size: 1.1rem;
}

@media only screen and (max-width: 480px) {
  /* For mobile phones: */
  #header_logo {
    transition: .5s; // all
    }

  .large-logo {
    width: 130px;
    max-height: 8vh;
  }

  .small-logo  {
    width: 6.125rem;
    height: 4vh;
  }
}

@media only screen and (min-width: 720px) {
  /* For larger phones and screens: */
  #header_logo {
    transition: .5s; // all
  }

  .large-logo {
    width: 260px;
    max-height: 18vh;
  }

  .small-logo  {
    width: 12.125rem;
    height: 6vh;
  }
}

/*! purgecss end ignore */

/*------------------- define footer color  ------------------*/
[data-background-color='grijs'] {
  background-color: $colorgrijs;
}

[data-footer-background-color='blue_gradient'] {
  // background: rgb(26, 26, 63);
  background: linear-gradient(90deg, rgba(26, 26, 63, 1) 0%, rgba(1, 72, 136, 1) 45%, rgba(187, 194, 194, 1) 100%);
  color: $white; /* make sure text of main menu in footer has white letters */
}

a.footer-brand {
  color: $white;
  text-decoration: none;
}

/*------------------- icon alignment and color -------------- */
.btn i {
  &.fas,
  &.far {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-12px, -12px);
    line-height: 1.5rem;
    width: 23px;
  }
}

.icons {
  i,
  svg {
    color: var(--fill-color-boat);
  }
}

.fas,
.fa,
.far {
  color: var(--fill-color-boat);
}

[data-background-color='boat-color'] {
  background-color: #013e75;
}


// .icon-facebook {
//   svg {
//     width: 1.5rem;
//     height: 2.5rem;

//     path {
//       fill: inherit;
//     }

//     [class^='shape-'] {
//       fill: color('gray-dark');
//     }

//   }
// }

//@extend-elements
//original selectors
//.icon-books svg, .icon-pencil svg, .icon-hour-glass svg, .icon-tools svg, .icon-keyboard svg
%extend-1 {
  fill: $boat-color;
  margin-left: 1em;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: scale(.7);
}

//original selectors
//.icon-camera svg, .icon-share2 svg, .icon-pen svg
%extend-2 {
  fill: $boat-color;
  height: 1.7em;
  width: 2em;
}

//original selectors
//.icon-hand-o-left svg, .icon-hand-o-right svg
%extend-3 {
  fill: $boat-color;
  height: 3em;
  width: 5em;
}

svg {
  margin-top: .5em;
  padding: 0;
  fill: $white;
  width: 2.5em;
  height: 2.5em;
}

.icon-price-tags svg,
.icon-price-tag svg {
  fill: $boat-color;
}

.icon-mail svg {
  margin-left: .2em;
  margin-top: .6em;
}

.icon-books svg,
.icon-pencil svg,
.icon-hour-glass svg,
.icon-tools svg,
.icon-keyboard svg {
  @extend %extend-1;
}

.icon-camera svg,
.icon-share2 svg,
.icon-pen svg {
  @extend %extend-2;
}

.icon-hand-o-left svg,
.icon-hand-o-right svg {
  @extend %extend-3;
}

.icon-sailing-boat-water svg {
  fill: $boat-color;
  width: 1em;
  height: 2em;
}

/* ----------- back to the top button ------------- */
.scrolltop {
  position: fixed;
  right: 3%;
  bottom: 10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--fill-color-boat);
  padding: .25rem;
  opacity: .5;
  transition: .4s ease-in-out 0s; // all
}

.scrolltop a {
  font-size: 18px;
  color: rgb(153, 6, 6);
}

/* ----------- GPS Track styling ----------- */
.gps-track li {
  margin-left: 30px;
}

/* -----------End GPS Track ----------- */

/* -----------Start Youtube ifram ----------- */

.youtube-player {
  position: relative;
  padding-bottom: 56.23%;
  /* Use 75% for 4:3 videos */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin: 5px;
}

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent;
}

.youtube-player img {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: auto;
  cursor: pointer;
  -webkit-transition: .4s all;
  -moz-transition: .4s all;
  transition: .4s all;
}

.youtube-player img:hover {
  filter: brightness(75%);
  -webkit-filter: brightness(75%);
}

.youtube-player .play {
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  position: absolute;
  background: url("//i.imgur.com/TxzC70f.png") no-repeat; // shows the play-button
  cursor: pointer;
}

/* -----------End Youtube iframe ----------- */

/* ----------- define text color navbar --------- * /
.navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item), .dropdown-menu .dropdown-item {
  color: var(--fill-color-boat);
} */

/* ---------- list items horizontal -----------*/
.list-group-horizontal {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;

  &>.list-group-item {
    border: 1px solid $colorborderlistgroup;
    display: table-cell;
    float: none;
    text-align: center;
    width: 1%;
    border-radius: 0;

    &:first-child {
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-bottom-right-radius: 3px;
    }
  }
}

// SCSS
.wrapper {
  margin: 0;
}

.social-share {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: -5px;

  > div {
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 10px;
    /* background-color: white; */
  }

  .btn-twitter {
    padding-top: 4px;
  }

  .btn-googleplus {
    margin-top: 4px;
  }

  .btn-pinterest {
    margin-top: 0;
  }
}

/* ---------- OVERRIDE GOOGLE TRANSLATE WIDGET CSS BEGIN ---------- */

/*! purgecss start ignore */

.goog-te-menu-frame {
  max-width: 100%;
  // 10mrch19 !important; //or whatever width you want
}

.goog-te-menu2 {
  //the element that contains the table of options
  max-width: 100%;
  // 10mrch19 !important;
  overflow: scroll;
  // 10mrch19 !important;
  box-sizing: border-box;
  // 10mrch19 !important; //fixes a padding issue
  height: auto;
  // 10mrch19 !important; //gets rid of vertical scroll caused by box-sizing
}

div#google_translate_element div.goog-te-gadget-simple {
  border: 0;
  // none;
  background-color: transparent;
  // background-color: #17548d; // #e3e3ff

  a.goog-te-menu-value {

    &:hover {
      text-decoration: none;
    }

    span {
      color: $color-google-menu;

      &[style='color: rgb(213, 213, 213);'],
      &[style='color: rgb(118, 118, 118);'],
      &[style='color: rgb(155, 155, 155);'],
      &[style='border-left: 1px solid rgb(187, 187, 187);'],
      &[style='border-left-color: rgb(187, 187, 187); border-left-width: 1px; border-left-style: solid;'] {
        display: none;
      }

      &:hover {
        color: $white;
      }
    }
  }
}

.goog-te-gadget-icon {
  display: none !important;
  // background: url('url for the icon') 0 0 no-repeat !important;
}

// Remove the down arrow
// when dropdown open

// div#google_translate_element div.goog-te-gadget-simple a.goog-te-menu-value span {
//   &[style='color: rgb(213, 213, 213);'],
//   &[style='color: rgb(118, 118, 118);'],
//   &[style='color: rgb(155, 155, 155);'],
//   &[style='border-left: 1px solid rgb(187, 187, 187);'],
//   &[style='border-left-color: rgb(187, 187, 187); border-left-width: 1px; border-left-style: solid;'] {
//     display: none;
//   }
// }

// after clicked/touched

// on page load (not yet touched or clicked)

// Remove span with left border line | (next to the arrow) in Chrome & Firefox

// Remove span with left border line | (next to the arrow) in Edge & IE11

// HIDE the google translate toolbar

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0 !important;
}

/*! purgecss end ignore */

/* ------------- OVERRIDE GOOGLE TRANSLATE WIDGET CSS END ---------------- */

/* .icon {
  / * display: inline-block;
  width: 1em;
  height: 90%;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; * /
  text-align: center;
  justify-content: center;
  vertical-align: text-top;
  width: 2.8rem;
  height: 2.5rem;
} */

/* .social-buttons .icon-rss,
.social-buttons .icon-pinterest,
.social-buttons .icon-facebook,
.social-buttons .icon-twitter,
.social-buttons .icon-gplus {
  fill: #fff;
  width: 1rem;
  height: 1.5rem;
} */

// SCSS
.social-buttons {
  .icon-rss,
  .icon-pinterest,
  .icon-facebook,
  .icon-twitter,
  .icon-gplus {
    fill: $white;
    width: 1rem;
    height: 1.5rem;
  }

  .icon-gplus,
  .icon-facebook {
    width: 1.6rem;
  }

  .icon-twitter {
    width: 1.3rem;
  }

}

// SCSS
/*.social-buttons {
  .icon-gplus,
  .icon-facebook {
    width: 1.6rem;
  }

  .icon-twitter {
    width: 1.3rem;
  }
}*/

.icon {
  text-align: center;
  justify-content: center;
  vertical-align: text-top;
  width: 2.8rem;
  height: 2.5rem;

  &.icon-mail {
    width: 2.1rem;
    margin-top: .01rem;
  }

  &.icon-facebook {
    margin-top: .2rem;
    margin-right: .1rem;
  }

  &.icon-pinterest-1,
  &.icon-facebook,
  &.icon-twitter,
  &.icon-gplus-1,
  &.icon-mail {
    fill: var(--fill-color-white);
  }

  &.icon-hour-glass,
  &.icon-calendar,
  &.icon-calendar1,
  &.icon-archive,
  &.icon-keyboard .icon.icon-tags,
  &.incon-chat,
  &.icon-left-hand,
  &.icon-right-hand,
  &.icon-share2,
  &.icon-camera {
    fill: var(--fill-color-boat);
  }

  &.icon-hour-glass,
  &.question,
  &.icon-calendar,
  &.icon-calendar1,
  &.icon-archive,
  &.icon-keyboard,
  &.icon-point-left,
  &.icon-point-right {
    height: 1rem;
  }

  &.icon-camera,
  &.icon-tags,
  &.icon-share2,
  &.icon-chat {
    height: 2rem;
  }

  &.icon-search {
    font-size: 2rem;
    fill: #faebd7;
    margin-bottom: .3rem;
  }
}

.scrolltop .icon.icon-up-hand {
  // fill: #faebd7;
  fill: var(--fill-color-boat);
  font-size: 2rem;
}

/* ---------------- End ICON Styling ---------------- */

#google_ad {
  min-height: 1px;
}

/* ------------ Start Table sort styling ------------- */
table.dataTable thead .sorting:after,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before {
bottom: .5em;
}
/* ------------ End Table sort styling ------------- */
//
// EOF
//
